@import 'src/styles/variables';
@import 'src/styles/typography';

body {
  font-weight: normal;
  font-family: Roboto, sans-serif; // default to current until redesign finished
}

.padding-0 {
  padding-left: 0;
  padding-right: 0;
}

.margin-0 {
  margin-left: 0;
  margin-right: 0;
}

iframe {
  border: none;
}

.rts-inverse-button {
  @include rts-body-3;
  color: $rts-lighter-green;
  background-color: $rts-white;
  padding: 0.625rem 1.25rem;
  border-radius: 4px;
  border: 1px solid $rts-lighter-green;
  box-sizing: border-box;

  &:disabled {
    color: $rts-cool-grey;
    border-color: $rts-cool-grey;
  }

  &:hover {
    color: $rts-darker-green;
    background-color: $rts-cool-grey;
  }
}

.rts-filled-button {
  @include rts-body-2;
  color: $rts-white;
  background-color: $rts-lighter-green;
  padding: 0.625rem 1.25rem;
  border-radius: 4px;
  border: 1px solid $rts-lighter-green;
  box-sizing: border-box;

  &:disabled {
    background-color: $rts-cool-grey;
    border-color: $rts-cool-grey;
  }

  &:hover {
    background-color: $rts-darker-green;
    border-color: $rts-darker-green;
  }
}

.rts-filled-primary-button {
  @include rts-body-2;
  color: $rts-white;
  background-color: $rts-dark-blue;
  padding: 0.625rem 1.25rem;
  border-radius: 4px;
  border: 1px solid $rts-dark-blue;
  box-sizing: border-box;

  &:disabled {
    background-color: $rts-cool-grey;
    border-color: $rts-cool-grey;
  }

  &:hover {
    background-color: #1c3887;
    border-color: #1c3887;
  }
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0 !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.feedback-button {
  position: absolute;
  bottom: 4.625rem;
  right: 0.625rem;
  border-radius: 4rem;
  background: #00a476;
  box-shadow: 0px 5px 10px 3px rgba(0, 164, 118, 0.3);
}

.chatbot-button {
  position: absolute;
  bottom: 0.625rem;
  right: 0.625rem;
  border-radius: 4rem;
  background: #00a476;
  box-shadow: 0px 5px 10px 3px rgba(0, 164, 118, 0.3);
}

.feedback-popover {
  left: -0.9375rem !important;
  border: 2px solid #01a476;
  top: -0.375rem !important;

  .popover-body {
    padding: 1.2rem 0.75rem 0.5rem 0.75rem;

    .mat-icon {
      position: absolute;
      top: -1rem;
      right: calc(50% - 1.5rem);
      border-radius: 4rem;
      background: #00a476;
      padding: 6px;
      width: 2rem;
      height: 2rem;
      font-size: 1.25rem;
    }
  }

  &:before,
  &:after {
    left: 95% !important;
  }

  &:before {
    bottom: -0.8125rem !important;
    margin-left: -0.6875rem !important;
    border-top-color: #01a476 !important;
  }
}

.chatbot-popover {
  left: -0.9375rem !important;
  border: 2px solid #01a476;
  top: -0.375rem !important;

  .popover-body {
    padding: 1.2rem 0.75rem 0.5rem 0.75rem;

    .mat-icon {
      position: absolute;
      top: -1rem;
      right: calc(50% - 1.5rem);
      border-radius: 4rem;
      background: #00a476;
      padding: 6px;
      width: 2rem;
      height: 2rem;
      font-size: 1.25rem;
    }
  }

  &:before,
  &:after {
    left: 95% !important;
  }

  &:before {
    bottom: -0.8125rem !important;
    margin-left: -0.6875rem !important;
    border-top-color: #01a476 !important;
  }
}

.mat-stroked-button {
  line-height: 2.125rem;
  border-radius: 0.25rem;

  &.mat-accent {
    background-color: $rts-white;
  }
}

.loading.mat-progress-spinner circle,
.mat-spinner circle {
  // stroke: $rts-medium-grey !important;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100% !important;
  }
}

.gm-style .gm-style-iw-c {
  border-radius: 4px;
  box-shadow: 0px 2px 7px 3px rgba(221, 229, 237, 0.5);

  .rts-info-window {
    h5 {
      color: #0c1c2a;
      @include rts-header-5;
      font-style: normal;
      font-weight: 500;
      line-height: 1.375rem;
      margin: 0;
    }

    p {
      margin: 0;
      color: #798996;
      @include rts-optional-label;
      font-weight: 500;
      line-height: 1.125rem;
      margin: 0.1rem 0 1rem 0;
    }
  }
}

.support-date-range-picker {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
    .mat-form-field-infix {
      border: none;
      padding: 8px 0px !important;
      top: -4px !important;
    }
    .mat-form-field-flex {
      margin: 0;
    }
    .mat-form-field-outline {
      top: 0;
      background: #fff;
      border-radius: 5px;
    }
    .mat-form-field-suffix {
      top: 4px !important;
    }
  }
}

.mat-flat-button {
  box-shadow:
    0 0 #0003,
    0 0 #00000024,
    0 0 #0000001f;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 8px 8px 8px 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}
