@import './variables';
@import './typography';
//global style for all mat-selects

.mat-select-placeholder {
  color: $rts-midnight-blue !important;
}

mat-form-field.mat-form-field-type-mat-select.select-input {
  width: 100%;

  mat-select {
    @include rts-body-1;
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      border-radius: 4px;
      background-color: $rts-cool-off-white;

      .mat-form-field-label {
        color: $rts-midnight-blue;
      }
    }
  }

  // multi-select-input styles
  &.multi-select-input {
    mat-select-trigger {
      display: flex;
      flex-wrap: wrap;
    }

    .selected-text {
      @include rts-body-3;
      color: $rts-green;
      background-color: $rts-lighter-green-20;
      padding: 0.5rem 0.625rem;
      border-radius: 4px;
      display: flex;
      margin: 0.125rem;
    }

    .mat-select-trigger {
      height: auto;
    }
  }
}

// multi-select-panel styles
.multi-select-panel {
  mat-option {
    mat-pseudo-checkbox {
      color: $rts-midnight-blue;
    }

    &.mat-selected {
      background-color: $rts-lighter-green-20;
      mat-pseudo-checkbox {
        color: $rts-green;
        &.mat-pseudo-checkbox-checked {
          background: $rts-green;
        }
      }

      .mat-option-text {
        color: $rts-lighter-green;
      }

      &:hover {
        background-color: $rts-lighter-green-20;
        .mat-option-text {
          color: $rts-lighter-green;
        }
      }
    }

    // disable not-selected options after user selects maxSelected
    &:not(.mat-selected).disable-option {
      pointer-events: none;
      opacity: 50%;
    }
  }
}
