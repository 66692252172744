//global style for all mat-dialogs

.container-device-modal .mat-dialog-container {
  // removes bootstrap gutters for the container device modal specifically
  padding: 0;
}

.chatbot-dialog-container {
  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}