@import './variables';
@import './typography';
//global style for all mat-inputs that are text-areas

mat-form-field.mat-form-field-type-mat-input.text-area-input {
  @include rts-body-1;
  color: $rts-midnight-blue;
  width: 100%;

  textarea {
    min-height: 10.725rem;
  }

  .mat-form-field-hint-wrapper {
    @media screen and (max-width: 767px) {
    }
  }

  .mat-input-element::placeholder {
    color: $rts-midnight-blue;
    opacity: 0.4;
    font-weight: 400;
  }
}
