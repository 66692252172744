@import "variables";

// global mdbootstrap datatable global styles
// this needed to be in a top level stylesheet to work in mdb dynamically generated components

.mdb-data-table-rts {
  border: 1px solid $rts-light-grey;
  background-color: $rts-white;

  &.rts-table-responsive {
    width: 100%;
    overflow-x: auto;
  }

  // regardless of window size, if no data expand the th to full width
  &.no-data {
    width: 100% !important;
    display: table;
  }

  thead {
    background-color: rgb(242, 242, 242);
    color: rgb(0, 0, 0);

    th {
      a {
        cursor: pointer;
      }
    }
  }

  tbody {

    th, td {
      font-size: .9rem;
      font-weight: normal;
      vertical-align: middle;
    }
  }
}

.table-footer {
  background-color: #fafafa;
  border: 1px solid $rts-light-grey;
  padding: 1.1rem 16px 1rem;

  mdb-table-pagination {

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding-left: 0;
      list-style: none;
      border-radius: .25rem;

      .page-item {

        &.disabled {
          cursor: not-allowed;
          pointer-events: none;
          color: #868e96;
        }

        .page-link {
          margin-left: 2px;
          margin-right: 2px;
          border-radius: 50%;
          color: #212529;
          transition: all 0.3s linear;
          outline: 0;
          border: 0;
          background-color: transparent;
          font-size: 0.9rem;

          &:hover {
            border-radius: 50%;
            transition: all 0.3s linear;
            background-color: #eee;
          }
        }
      }
    }
  }
}
