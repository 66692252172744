@import './variables';
@import './typography';
//global style for all mat-inputs

mat-form-field.mat-form-field-type-mat-input.text-input {
  width: 100%;

  input {
    @include rts-body-1;
    color: $rts-midnight-blue;
  }

  // chrome autocomplete results have their own background color that clashes with the input background color this overrides to match
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $rts-white inset !important;
  }

  .mat-form-field-hide-placeholder {
    mat-label {
      @include rts-body-3;
    }
  }

  .mat-form-field-should-float {
    mat-label {
      @include rts-floating-label-small;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      border-radius: 4px;
      background-color: $rts-cool-off-white;

      .mat-form-field-label {
        color: $rts-midnight-blue;
      }
    }
  }

  .mat-input-element::placeholder {
    color: $rts-midnight-blue;
    opacity: 0.4;
    font-weight: 400;
  }

  .mat-form-field-suffix {
    display: flex;
    align-self: center;
  }

  .mat-form-field-prefix {
    top: 0;
    font-family: 'CircularStd-Book', Helvetica, sans-serif;
    font-size: 1rem;
  }
}

// Search input
.rts-search-input {
  @include rts-body-3; // font-size controls the size of the mat element
  width: 100%;

  button {
    border: none;
    background-color: $rts-white;
  }

  .mat-form-field-wrapper {
    margin: 0 !important;
  }

  div.mat-form-field-prefix {
    top: 0.4rem;
  }

  .mat-form-field-infix {
    padding: 0.5em 0 0.9em 0;
  }

  div.mat-form-field-suffix {
    top: 0.4rem;
  }

  .mat-form-field-outline {
    background-color: $rts-white;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $rts-midnight-blue;
    opacity: 0.4; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $rts-midnight-blue;
  }
}
