// customized bootstrap docs - https://getbootstrap.com/docs/5.0/customize/sass/

// required - first import
@import '../../node_modules/bootstrap/scss/functions';

// default variable overrides have to go here

// make gutters and containers the same width
.container-fluid {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

$grid-gutter-width: 1rem !default;

// required - second import
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

// optional - container/grid imports
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/grid';

// optional - used for current nav dropdown - remove if nav changed
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/dropdown';

// optional - used for current mdb tables - can remove if/when removed
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/utilities';

// optional - used for weekly schedule on dashboard
@import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/popover';
