@import './variables';
@import './typography';
//global style for all mat-date-picker

mat-form-field.mat-form-field-type-mat-input.date-picker {
  width: 100%;

  input {
    @include rts-body-1;
    color: $rts-midnight-blue;
  }

  // chrome autocomplete results have their own background color that clashes with the input background color this overrides to match
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $rts-white inset !important;
  }

  .mat-input-element::placeholder {
    color: $rts-midnight-blue;
  }
}

mat-form-field.multiple-date-picker.mat-form-field-type-mat-chip-list {
  .mat-form-field-suffix {
    align-self: center;
  }
  .mat-chip-list {
    min-height: 18px;
    display: flex;
  }
}

.multiDatePicker
  .mat-calendar-body-cell.selected
  > .mat-calendar-body-cell-content,
.multiDatePicker
  .mat-calendar-body-cell.selected:hover
  > .mat-calendar-body-cell-content,
.multiDatePicker
  .mat-calendar-body-cell.selected
  > .mat-calendar-body-cell-content:hover {
  background-color: $accent !important;
  color: #fff !important;
}
