// RTS colors
$rts-darker-green: #008f6b;
$rts-lighter-green: #00a476;
$accent: #00a476;
$rts-lighter-green-20: rgba(0, 164, 118, 0.2);
$rts-bright-mint: #98e1b8;
$rts-bright-mint-half: rgba(152, 225, 184, 0.5);
$rts-dark-blue: #21409a;
$rts-dark-blue-20: rgba(33, 64, 154, 0.2);
$rts-sky-blue: #8dd8e8;
$rts-transparent-lighter-blue: #1400ff33;
$rts-midnight-blue: #0c1c2a;
$rts-cool-grey: #dde5ed;
$rts-dark-greyish-blue: #798996;
$rts-grey: #c0cbd6;
$rts-cool-off-white: #f5f7fa;
$rts-white: #ffffff;
$rts-indication-red: #ef314e;
$rts-indication-yellow-gold: #fba300;
$rts-indication-yellow-gold-20: rgba(255, 197, 89, 0.2);
$rts-indication-blue: #1400ff;

// possibly old now but in use .....
$rts-light-orange: #faa736;
$rts-dark-orange: #fd5623;
$rts-dark-orange-half: rgba(253, 86, 35, 0.5);
$rts-charcoal: #535860;
$rts-medium-grey: #f2f2f2;
$rts-light-medium-grey: #f1f1f1;
$rts-light-grey: #e4e1e1;
$rts-dark-cool-grey: #5d757e;

// RTS colors - OLD - Will remove after redesign
$rts-orange: #f87c56;
$rts-green: #00966c;
$rts-black: #3d3935;
$rts-light-grey: #c7c9c7;
$rts-off-white: #dde5ed;

// Mat theme - customer Portal
$md-accent: (
  50: #e0f4ef,
  100: #b3e4d6,
  200: #80d2bb,
  300: #4dbf9f,
  400: #26b28b,
  500: #00a476,
  600: #009c6e,
  700: #009263,
  800: #008959,
  900: #007846,
  A100: #a6ffd4,
  A200: #73ffbb,
  A400: #40ffa2,
  A700: #26ff96,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-primary: (
  50: #e4e8f3,
  100: #bcc6e1,
  200: #90a0cd,
  300: #6479b8,
  400: #425da9,
  500: #21409a,
  600: #1d3a92,
  700: #183288,
  800: #142a7e,
  900: #0b1c6c,
  A100: #9facff,
  A200: #6c80ff,
  A400: #3954ff,
  A700: #1f3eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
