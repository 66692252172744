@import 'src/styles/variables';
@import 'src/styles/typography';

.mat-table-global {
  border: 1px solid $rts-light-grey;
  border-radius: 4px;

  .mat-table {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
  }

  .mat-paginator {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  table {
    width: 100%;
    background-color: $rts-white;

    .table-header-row {
      height: 3.5rem;

      th {
        @include rts-body-4;
        background-color: $rts-white;
        color: $rts-midnight-blue;
      }

      .mat-sort-header-arrow {
        color: $rts-midnight-blue;
      }
    }

    .table-data-row {
      height: 3.25rem;

      td {
        @include rts-body-3;
        color: $rts-midnight-blue;
      }

      // used in the support-list-table to hide the collapsable row border
      &.table-data-row-hidden {
        border-bottom: hidden;
      }
    }

    .action-column.mat-header-cell,
    .action-column.mat-cell {
      .action-icon-container {
        margin: auto;
        height: 1.5rem;
        width: fit-content;
        background-color: $rts-white;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .element-row {
      background-color: $rts-cool-off-white;
    }

    .element-detail {
      overflow: hidden;

      .description-row-header {
        @include rts-captions;
        color: $rts-grey;
        margin-top: 2rem;
      }

      .description-row-content {
        @include rts-body-1;
        color: $rts-midnight-blue;
        white-space: pre-wrap;
      }

      button {
        @include rts-body-3;
        padding: 0.5625rem 1.25rem;
        border: none;
        border-radius: 4px;
        margin-top: 2.75rem;
        margin-bottom: 2rem;
        border: 1px solid $rts-lighter-green;

        &.mat-button {
          background-color: $rts-lighter-green;
          color: $rts-white;

          &.mat-button-disabled {
            background-color: #0000001f;
            color: #00000042;
            border: 1px solid #0000001f;
          }
        }

        &.mat-stroked-button {
          background: $rts-white;
          color: $rts-lighter-green;
        }
      }
    }

    tr.expanded-detail-row {
      height: 0;
    }
  }

  mat-paginator {
    color: $rts-midnight-blue;
    @include rts-body-3;
  }

  // mobile responsive table styles
  @media (max-width: 768px) {
    .mat-table[matTableResponsive] {
      .mat-header-row {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        position: sticky;
        top: 0;
        z-index: 2;

        th.mat-header-cell {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 1rem 0.5rem;
          justify-content: space-around;

          & > .mat-sort-header-container {
            padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
          }

          &:not([mat-sort-header]),
          &.mat-sort-header-disabled,
          &.mat-column-actions {
            display: none;
          }
        }
      }

      .mat-row {
        height: auto;
        border-bottom: 2px solid $rts-midnight-blue;
        border-top: 2px solid $rts-midnight-blue;

        td.mat-cell:not(.mat-column-actions) {
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 0.5rem;
          text-align: right;
          word-break: break-word;
          align-items: center;

          & > * {
            grid-column: 2;
          }

          &:before {
            content: attr(data-column-name);
            text-align: left;
          }
        }

        td.mat-cell.mat-column-expandedDetail#support-list-details {
          display: flex !important;
          padding: 0;
          border: none;

          &:before {
            content: none;
          }

          .description-row-header {
            text-align: start;
            margin-top: 0;
          }

          .description-row-content {
            text-align: start;
          }

          &.details-collapsed {
            padding: 0 !important;
          }

          &.details-expanded {
            padding: 0.5rem;
          }
        }

        td.mat-column-actions {
          display: flex;
          justify-content: center;
          padding: 0 0.5rem;

          & > button {
            margin: 0.5rem 0;
          }
        }

        &:last-of-type td:last-of-type {
          border: none;
        }

        &:not(:last-of-type) td:last-of-type {
          border-bottom: 0.5rem solid #fafafa;
        }
      }
    }

    .mat-card,
    .mat-expansion-panel {
      .mat-table[matTableResponsive] {
        border: 0.5rem solid #fafafa;
      }
    }

    // mobile table footer
    mat-paginator {
      padding-top: 0.5rem;

      .mat-paginator-container {
        justify-content: center;
      }
    }
  }
}
