.mat-tab-label,
.mat-tab-link {
  font-size: 16px;
  font-weight: 700;
  font-family: 'CircularStd-Book';

  &:hover {
    text-decoration: none;
    color: $accent;
  }
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active,
.mat-tab-link.mat-tab-label-active {
  color: $accent;
}

.mat-header-cell {
  font-weight: 500;
  font-family: CircularStd-Bold, Helvetica, sans-serif;
  font-size: 0.875rem;
  background-color: #ffffff;
  color: #0c1c2a;
}
