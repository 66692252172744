.mat-button-toggle-appearance-standard span.mat-button-toggle-label-content {
  padding: 0 1.25rem;
}

.mat-button-toggle-group.cp.mat-button-toggle-group-appearance-standard {
  border: solid 1px $rts-green;

  & .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px $rts-green;
  }

  & .mat-button-toggle-label-content {
    line-height: 38px;
  }

  & .mat-button-toggle {
    color: $rts-green;
    background-color: $rts-white;

    &-checked {
      background-color: $rts-green;
      color: $rts-white;
    }
  }
}

.mat-raised-button {
  line-height: 36px;
  border-radius: 4px;
}
