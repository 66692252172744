@import 'src/styles/variables';
@import 'src/styles/typography';

angular2csv {
  button {
    @include rts-body-3;
    border: none;
    outline: inherit;
    cursor: pointer;
    border-radius: 4px;
    color: $rts-green;
    visibility: hidden;
    min-width: 138px; // px to match the figma padding below
    min-height: 43px; // px to match the figma padding below
    position: relative;
    padding: 0;
  }

  button:hover {
    text-decoration: underline;

    &:after {
      background-color: $rts-cool-grey;
    }
  }

  // needed to replace button text in angular2csv
  button:after {
    @include rts-body-3;
    content: 'Download CSV';
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $rts-white;
    border: 1px solid $rts-lighter-green;
    padding: 0.625rem 1.25rem;
    border-radius: 4px;
  }
}
