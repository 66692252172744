@import './fonts';

// RTS headers
@mixin rts-header-1 {
  font-family: CircularStd-Bold, Helvetica, sans-serif;
  font-size: 2.5rem;
}

@mixin rts-header-2 {
  font-family: CircularStd-Medium, Helvetica, sans-serif;
  font-size: 1.75rem;
}

@mixin rts-header-3 {
  font-family: CircularStd-Bold, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.rts-header-3 {
  @include rts-header-3;
}

@mixin rts-header-4 {
  font-family: CircularStd-Medium, Helvetica, sans-serif;
  font-size: 1.125rem;
}

@mixin rts-header-5 {
  font-family: CircularStd-Medium, Helvetica, sans-serif;
  font-size: 1rem;
}

// RTS body
@mixin rts-body-1 {
  font-family: CircularStd-Book, Helvetica, sans-serif;
  font-size: 1rem;
}

.rts-body-1 {
  @include rts-body-1;
}

@mixin rts-body-2 {
  font-family: CircularStd-Bold, Helvetica, sans-serif;
  font-size: 1rem;
}

@mixin rts-body-3 {
  font-family: CircularStd-Book, Helvetica, sans-serif;
  font-size: 0.875rem;
}

@mixin rts-body-4 {
  font-family: CircularStd-Bold, Helvetica, sans-serif;
  font-size: 0.875rem;
}

@mixin rts-large-body-1 {
  font-family: CircularStd-Book, Helvetica, sans-serif;
  font-size: 2.25rem;
}

@mixin rts-subtitle {
  font-family: CircularStd-Medium, Helvetica, sans-serif;
  font-size: 1rem;
}

// RTS data
@mixin rts-data-1 {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 0.875rem;
}

@mixin rts-data-2 {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 0.75rem;
}

//RTS forms
@mixin rts-floating-label-small {
  font-family: CircularStd-Medium, Helvetica, sans-serif;
  font-size: 0.625rem;
}

@mixin rts-optional-label {
  font-family: CircularStd-Book, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin rts-captions {
  font-family: CircularStd-Book, Helvetica, sans-serif;
  font-size: 0.75rem;
}

@mixin rts-chart-key-text {
  font-family: CircularStd-Bold, Helvetica, sans-serif;
  font-size: 0.7rem;
}
