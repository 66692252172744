@font-face {
  font-family: 'CircularStd-Black';
  src:
    local('CircularStd-Black'),
    url('../assets/fonts/Circular/CircularStd-Black.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd-BlackItalic';
  src:
    local('CircularStd-BlackItalic'),
    url('../assets/fonts/Circular/CircularStd-BlackItalic.otf')
      format('opentype');
}

@font-face {
  font-family: 'CircularStd-Bold';
  src:
    local('CircularStd-Bold'),
    url('../assets/fonts/Circular/CircularStd-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd-BoldItalic';
  src:
    local('CircularStd-BoldItalic'),
    url('../assets/fonts/Circular/CircularStd-BoldItalic.otf')
      format('opentype');
}

@font-face {
  font-family: 'CircularStd-Book';
  src:
    local('CircularStd-Book'),
    url('../assets/fonts/Circular/CircularStd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd-BookItalic';
  src:
    local('CircularStd-BookItalic'),
    url('../assets/fonts/Circular/CircularStd-BookItalic.otf')
      format('opentype');
}

@font-face {
  font-family: 'CircularStd-Medium';
  src:
    local('CircularStd-Medium'),
    url('../assets/fonts/Circular/CircularStd-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd-MediumItalic';
  src:
    local('CircularStd-MediumItalic'),
    url('../assets/fonts/Circular/CircularStd-MediumItalic.otf')
      format('opentype');
}

@font-face {
  font-family: 'CircularXX-Thin';
  src:
    local('CircularXX-Thin'),
    url('../assets/fonts/Circular/CircularXX-Thin.otf') format('opentype');
}
